.dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 90vh;

  @media (max-width: 900px) {
    height: auto;
  }

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 900px) {
      width: 100%;
      margin-top: 5rem;
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 5px;

      span {
        font-size: 2rem;
        font-weight: 900;
      }
    }

    .statContainer {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      @media (max-width: 900px) {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
