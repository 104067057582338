.stat {
  width: 31%;
  display: flex;
  background-color: #fff;
  box-shadow: 1px 2px 17px 0px rgba(0, 0, 0, 0.3);
  height: 8rem;

  @media (max-width: 900px) {
    width: 90%;
  }

  .statWrapper {
    display: flex;
    width: 100%;
    padding: 15px;

    .left {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        text-transform: capitalize;
        font-size: 13px;
        color: gray;
        font-weight: bold;
      }

      .amount {
        font-weight: bolder;
        font-size: 2rem;
        justify-self: flex-end;
      }
    }

    .right {
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: end;

      .percent {
      }

      .compared {
        font-size: 12px;
        color: gray;
      }
    }
  }
}
